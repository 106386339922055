<template>
  <PageContent :title="$t('bankAccountListingDetails.title')">
    <div class="box is-8">
      <div class="column is-8 has-text-left ">
        <h4>
          {{ finBankName }}
        </h4>
      </div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <transition name="fade" mode="out-in" appear>
          <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
            <fieldset>
              <div class="form-grid">
                <div class="column is-8">
                  <valid-input
                    name="Description"
                    label="Description"
                    maxlength="100"
                    type="text"
                    vid="description"
                    placeholder="Description"
                    rules="required|max:100"
                    v-model="transaction.description"
                  />
                </div>
                <div class="column is-8">
                  <valid-input
                    name="Balance"
                    label="Balance"
                    type="text"
                    vid="amount"
                    placeholder="Balance"
                    rules="required"
                    v-model="transaction.amount"
                  />
                </div>
                <div class="column is-8">
                  <valid-input
                    name="Check Number"
                    label="Check Number"
                    maxlength="50"
                    type="text"
                    vid="checkNumber"
                    placeholder="Check Number"
                    rules="required|max:50"
                    v-model="transaction.checkNumber"
                  />
                </div>
              </div>
            </fieldset>
            <fieldset>
              <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                <div class="field">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
            <button
              @click.prevent="confirmTransactionDelete(transaction.bankAccountListingID)"
              type="submit"
              class="button is-primary is-rounded"
              :disabled="loading"
            >
              Delete</button
            ><span style="padding-left:20px"></span>
            <button type="submit" class="button is-primary is-rounded" :disabled="loading">
              Update
            </button>
          </form>
        </transition>
      </ValidationObserver>
    </div>
  </PageContent>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import PageContent from '@/containers/PageContent'
import { ValidationError } from '@/services/errors'
import kms from '@/services/kms'
import { mapState, mapActions } from 'vuex' //mapGetters
import { ToastProgrammatic as Toast } from 'buefy'

// components
import ValidInput from '@/components/inputs/ValidInput'

import moment from 'moment'
const today = moment()

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

function notifyMessage(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-success'
  })
}

let DEFAULT_FORM_VALUES = {
  description: '',
  amount: 0,
  checkNumber: ''
}

export default {
  components: {
    PageContent,
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  data: function() {
    return {
      formComplete: false,
      formData: {
        ...DEFAULT_FORM_VALUES
      },
      loading: true,
      finInstBankName: '',
      bankAccountListingID: 0,
      formatDateFn: function(value) {
        return value != null
          ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss.967Z')
          : ''
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      transaction: state => state.bankaccount.bankAccountListing
    }),

    finBankName() {
      return this.transaction == null ||
        this.transaction.bankAccount == null ||
        this.transaction.bankAccount.financialInstitution == null
        ? ''
        : `${this.transaction.bankAccount.financialInstitution.name} - ${this.transaction.bankAccount.name}`
    }
  },

  mounted() {
    this.resetForm()
  },

  watch: {
    hoaId() {
      this.resetForm()
    }
  },

  methods: {
    ...mapActions('bankaccount', ['loadBankAccountListing']),

    confirmTransactionDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting transaction',
        message: 'Are you sure you want to <b>delete</b> this transaction?',
        confirmText: 'Delete Transaction',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteBankAccountListing(id)
      })
    },

    async resetForm() {
      this.loading = true
      this.formData = { ...DEFAULT_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }

      this.finInstBankName = this.$route.params.finInstBankName
      this.bankAccountListingID = this.$route.params.id

      console.debug(`params= ${this.bankAccountListingID} - ${this.finInstBankName}`)

      await this.loadBankAccountListing(this.bankAccountListingID)

      console.debug('loadBankAccountListing=' + JSON.stringify(this.transaction))

      this.loading = false
    },

    getFormPayload() {
      const payload = {
        bankAccountListingID: this.transaction.bankAccountListingID,
        bankAccountID: this.transaction.bankAccountID,
        hoaID: this.hoaId,
        description: this.transaction.description || '',
        amount: parseFloat(this.transaction.amount),
        checkNumber: this.transaction.checkNumber,
        transactionDate: this.formatDateFn(today.toDate())
      }
      return payload
    },

    async onFormSubmit() {
      try {
        this.loading = true
        const returnPayload = this.getFormPayload()
        console.debug(returnPayload)

        const path = `/CheckbookReconciliation/BankAccountListing`
        const results = await kms.put(path, returnPayload)

        console.debug(JSON.stringify(results))

        if (results.bankAccountListingID >= 0) {
          this.formComplete = true
          this.$router.push({
            name: 'bankAccount',
            params: { id: this.transaction.bankAccountID }
          })
          notifyMessage(`${this.transaction.bankAccount.name} was successfully updated.`)
        } else {
          notifyError('There was a problem updating your transaction.')
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        } else {
          notifyError(e)
        }
      }
      this.loading = false
    },

    async deleteBankAccountListing(id) {
      if (id <= 0) {
        notifyError('Unable to delete this transaction.')
        return
      }

      try {
        this.loading = true
        const data = { bankAccountListingID: id, forceDelete: false }
        const path = `/CheckbookReconciliation/BankAccountListing/${id}?forceDelete=false`
        const results = await kms.delete(path, data)

        console.debug(JSON.stringify(results))

        if (results.recordID >= 0) {
          this.formComplete = true
          this.loading = false
          this.$router.push({
            name: 'bankAccount',
            params: { id: this.transaction.bankAccountID }
          })
          notifyMessage(`${this.transaction.bankAccount.name} was successfully deleted.`)
        } else {
          notifyError('There was a problem deleting this transaction.')
        }
      } catch (e) {
        // If this is a validation error, get the details for each field
        if (e instanceof ValidationError) {
          this.$refs.form.setErrors(e.fields)
        }

        notifyError(e)
      }
    }
  },
  i18n: {
    messages: {
      en: { bankAccountListingDetails: { title: 'Transaction Details' } }
    }
  }
}
</script>
